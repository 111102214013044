import React, { Component } from 'react';
import Title from './Title';
import PriceItem from './PriceItem';

class Results extends Component {
    constructor(){
        super();
        this.state = {
            posts:[
                 {
                    "id": 102,
                    "ptitle": "Billie B.",
                    // "info": "Most popular choice",
                    "rate": "Lost 78 Lbs",
                    "image": "outcomes/Billie.png",
                    'list1': '"At 223 lbs. I felt ok but not good. I ended up in urgent care because my knee swelled and I could barely walk. X-rays showed the cartilage is gone and I would need surgery in the near future. I decided to make some changes. I have struggled with me weight my whole life. I started my journey in June 2020, with the Freshbit app and determination, I began. For me it made all the difference! It lets me know what and when I can eat by balancing carbs, protein, and fat for the entire day. It even tells me if I need to add vitamins and nutrients to my diet. I set my calorie goal and Freshbit helps me stay consistent. Now I am 145 lbs. I feel better than I have in years and my knee is doing great without surgery or medications!"',
                    // 'list2': '10GB Disk Space',
                    // 'list3': 'Powerful Admin Panel',
                    // 'list4': '24/7 E-mail support',
                },
                {
                    "id": 100,
                    "ptitle": "Sandy B.",
                    // "info": "Sandy B.",
                    "rate": "Lost 61 Lbs",
                    "image": "outcomes/Sandy.png",
                    'list1': '"I lost over 60 pounds with Freshbit, I feel great. I went from a size 20 to size 8. My knees no longer hurt. I can walk fast again. Since I have substituted the high carb foods I was eating with low carb substitutions that I really like, I am not going to go back to my high carb lifestyle. As the months progressed (since I started), even though I was still losing weight, I had some days where I felt the need for validation. This is where the forums on Freshbit helped me. Finding other like-minded people helped revitalize me. We shared stories, tips, and I learned of new foods that were available."',
                    // 'list2': '10GB Disk Space',
                    // 'list3': 'Powerful Admin Panel',
                    // 'list4': '24/7 E-mail support',
                },
                {
                    "id": 101,
                    "ptitle": "Tim A.",
                    // "info": "Most popular choice",
                    "rate": "Lost 40 Lbs",
                    "image": "outcomes/Tim.png",
                    'list1': '"To date, I have lost about 40lbs and I feel more energetic. When I started this app, I weighed in at my doctor’s office at 311lbs. I was sluggish and unhealthy. The news from my doctor gave me a wake-up call. She stated that my A1C was at 8 and I was officially a T2 diabetic. I looked for apps and found the Freshbit app. Immediately, I changed my eating and started logging everything I ate. My son had had T2 Diabetes and had beat it through diet and exercise. I committed to beating this. Now, my knees are far less stressed and sleep better (not so much snoring). I (recently) went to my doctor to get my A1C tested and it came back normal at 5.1. 3 months and I have dropped over 40 lbs, as well as beating type 2 diabetes."',
                    // 'list2': '10GB Disk Space',
                    // 'list3': 'Powerful Admin Panel',
                    // 'list4': '24/7 E-mail support',
                },
            ]
        };
    }
    render() {
        return (
            <section className="priceing_area_two" id="results">
                <div className="container">
                    <Title Stitle="User Stories" pfont="What our users have been able to achieve using the app"/>
                    <div className="row">
                        {this.state.posts.map(post =>(
                          <PriceItem key={post.id} post ={post} image={post.image}/>
                        ))}
                    </div>
                </div>
            </section>
        );
    }
}

export default Results;