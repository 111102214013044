import React, { Component } from 'react';
import Title from '../Title'

class Testimonial extends Component {
    render() {
        return (
            <section className="testimonial_area_two" id="testimonials">
                <div className="container">
                    <Title Stitle="Loved by our users" pfont=""/> 
                    <div id="carouselExampleIndicators" className="carousel slide testimonial_info" data-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className="carousel_text"><img src={require('../../images/line/quote2.png')} alt="f_img"/>
                                    <p>Great app thats very user friendly. Really like the images of the food for quick reference. Been using for a month now and down 10lbs! </p>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="carousel_text"><img src={require('../../images/line/quote2.png')} alt="f_img"/>
                                    <p>Every recipe we've tried is amazing. It is a great resource.</p>
                                </div>
                            </div>
                            <div className="carousel-item ">
                                <div className="carousel_text"><img src={require('../../images/line/quote2.png')} alt="f_img"/>
                                    <p>This application is for understanding. You can use the scanner on your phone to add food and it gives you recipes. I love it 💜</p>
                                </div>
                            </div>
                            <div className="carousel-item ">
                                <div className="carousel_text"><img src={require('../../images/line/quote2.png')} alt="f_img"/>
                                    <p>Great user friendly app! Keeps track of macronutrients which is just what I've been looking for.</p>
                                </div>
                            </div>
                        </div>
                        <ol className="carousel-indicators">
                            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active">
                                <div className="slider_thumbs"><img height="80" width="80" src={require('../../images/home/user4.png')}  alt="Jonathan"/>
                                    <div className="thumbs_text">
                                        <h2>Jonathan Chapin</h2>
                                    </div>
                                </div>
                            </li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="1" className="">
                                <div className="slider_thumbs"><img height="80" width="80" src={require('../../images/home/user2.png')}  alt="Maria"/>
                                    <div className="thumbs_text">
                                        <h2>Maria Heitner</h2>
                                    </div>
                                </div>
                            </li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="2" className="">
                                <div className="slider_thumbs"><img height="80" width="80" src={require('../../images/home/user1.png')} alt="Rolland"/>
                                    <div className="thumbs_text">
                                        <h2>Rolland Hedemann</h2>
                                    </div>
                                </div>
                            </li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="3" className="">
                                <div className="slider_thumbs"><img height="80" width="80" src={require('../../images/home/user3.png')}  alt="Deb"/>
                                    <div className="thumbs_text">
                                        <h2>Deb Schmenk</h2>
                                    </div>
                                </div>
                            </li>
                        </ol>
                        <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev"><i className="ti-arrow-left"></i></a><a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next"><i className="ti-arrow-right"></i></a>
                    </div>
                </div>
            </section>
        );
    }
}

export default Testimonial;