import React, { Component } from 'react';
import Title from '../Title';
import $ from 'jquery';

class Faq extends Component {
    componentDidMount(){
        $(".faq_accordian_two .card").click(function(){
            $(".faq_accordian_two .card").removeClass("active");
            $(this).addClass('active');
        });
    }
    render() {
        return (
            <section className="faq_solution_area features_area_pad" id="faq">
                <ul className="memphis-parallax hidden-xs hidden-sm white_border">
                    <li data-parallax='{"x": -100, "y": 100}'><img className="br_shape" src={require ('../../images/line/fq_line-1.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": -200, "y": 200}'><img className="br_shape" src={require('../../images/line/fq_line-2.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": -150, "y": 150}'><img className="br_shape" src={require('../../images/line/fq_line-3.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 50, "y": -50}'><img className="br_shape" src={require('../../images/line/fq_line-4.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": -150, "y": 150}'><img src={require('../../images/line/fq_line-5.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": -100, "y": 100}'><img src={require('../../images/line/fq_line-6.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 50, "y": -50}'><img src={require('../../images/line/fq_line-7.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 250, "y": -250}'><img src={require('../../images/line/fq_line-8.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 150, "y": -150}'><img src={require('../../images/line/fq_line-9.png')} alt="f_img"/></li>
                    <li data-parallax='{"y": -180}'><img src={require('../../images/line/fq_line-10.png')} alt="f_img"/></li>
                </ul>
                <div className="container">
                    <Title Stitle="Supportive Community" pfont="Freshbit's growing community of users are helping each other with their diet goals."/>
                    <div className="row ">
                        <div className="col-md-6 d_flex">
                            <div id="accordion" className="faq_accordian_two">
                                <div className="card wow fadeInUp active">
                                    <div className="card-header" id="heading1">
                                        <h5 className="mb-0"><button className="btn btn-link" data-toggle="collapse" data-target="#collapse0" aria-expanded="true" aria-controls="collapse0">Where is the nutrition data coming from?</button></h5>
                                    </div>
                                    <div id="collapse0" className="collapse show" aria-labelledby="heading1" data-parent="#accordion">
                                        <div className="card-body">
                                            <p><b>Answer:</b> We are using the most recent data provided by the United States Department of Agriculture (USDA).</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card wow fadeInUp ">
                                    <div className="card-header" id="heading2">
                                        <h5 className="mb-0"><button className="btn btn-link" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">How are recommendations determined?</button></h5>
                                    </div>
                                    <div id="collapse1" className="collapse " aria-labelledby="heading2" data-parent="#accordion">
                                        <div className="card-body">
                                            <p><b>Answer:</b> We are using guidelines from the most recent version of The U.S. Department of Health and Human Services Dietary Guidelines.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card wow fadeInUp ">
                                    <div className="card-header" id="heading3">
                                        <h5 className="mb-0"><button className="btn btn-link" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">Can I track net carbs insteal of total carbs?</button></h5>
                                    </div>
                                    <div id="collapse2" className="collapse " aria-labelledby="heading3" data-parent="#accordion">
                                        <div className="card-body">
                                            <p><b>Answer:</b> Yes, go to the "More" section from the footer menu, tap on Settings, then tap on the "Track Net Carbs" switch.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card wow fadeInUp ">
                                    <div className="card-header" id="heading4">
                                        <h5 className="mb-0"><button className="btn btn-link" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">Can I customize my Calorie goal?</button></h5>
                                    </div>
                                    <div id="collapse3" className="collapse " aria-labelledby="heading4" data-parent="#accordion">
                                        <div className="card-body">
                                            <p><b>Answer:</b> Yes, go to the "More" section from the footer menu, tap on "Settings", then tap on "My Goal" then tap on "Set Daily Calorie / Macro Goals".</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="faq_image_mockup wow fadeInUp" data-wow-delay="200ms"><img height="825" width="715" src={require('../../images/home/community.png')} alt="featured"/></div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Faq;