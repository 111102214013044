import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class BannerContent extends Component {
    render(){
        var { name, topic} = this.props;
        return(
            <React.Fragment>
                <div className="n_banner_content" style={{    display: "flex",flexDirection: "column",justifyContent: "center",alignItems: "center"}}>
                    <h2 className="wow fadeInUp" data-wow-delay="300ms" style={{marginTop: '25%'}}>We have a new home<br/></h2>
                    <a href="http://www.aspire.world" style={{marginTop: 20, marginBottom: 30}} className="n_banner_btn wow fadeInUp" data-wow-delay="450ms"> Go to new Home </a>
                    {
                      //  <h2 className="wow fadeInUp" data-wow-delay="300ms">Welcome to a Healthier You<br/></h2>
                    //<a href="./en/RegisterOptions" style={{marginTop: 20, marginBottom: 30}} className="n_banner_btn wow fadeInUp" data-wow-delay="450ms"> Sign up for Free </a>
                    //<iframe class="marketing-video" width="514" height="289" src="https://www.youtube.com/embed/mIEzx1RxVjk?rel=0" title="YouTube video player" frameborder="0" allow="fullscreen;accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"  allowfullscreen></iframe>
                    }
                    {
                        //<p className="wow fadeInUp" data-wow-delay="500ms"><b>Losing weight</b> and <b>keeping a healthy diet</b> is hard, Freshbit makes it easy. Freshbit is your AI-driven visual diet diary, helping you at each step of your diet journey.</p>
                    }
                    
                    {
                      //<div className="app-stores">
                        //<a href="https://itunes.apple.com/app/id1403059908" className="btn btn-normal apps-button wow fadeInUp" data-wow-delay="450ms"><i className="fa fa-apple"></i> <span>Download on The</span><br/> App Store </a>
                        //<a href="https://play.google.com/store/apps/details?id=com.bitapps.freshbit" className="btn btn-normal apps-button wow fadeInUp" data-wow-delay="450ms"><i className="fa fa-android"></i> <span>Get it on</span><br/> Google Play </a>
                    //</div>     
                    }
                    
                </div>
            </React.Fragment>
            
        );
    }
}

export default BannerContent;