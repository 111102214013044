import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class Footer extends Component {
    render() {
        return (
            <footer className="footer-five">
                <div className="footer-top">
                    <div className="container">
                        <div className="row footer_sidebar">
                            
                            {
                                // <div className="widget footer-widget col-xs-6 col-sm-6 col-md-3 wow fadeIn widget4 widget_social">
                                //     <h4 className="widget_title_two">Social Link</h4>
                                //     <ul className="social-icon">
                                //         <li><a href="."><i className="fa fa-facebook"></i>Facebook</a></li>
                                //         <li><a href="."><i className="fa fa-instagram"></i> Instagram </a></li>
                                //         <li><a href="."><i className="fa fa-linkedin"></i> Linkedin </a></li>
                                //         <li><a href="."><i className="fa fa-pinterest"></i> Pinterest </a></li> 
                                //     </ul>
                                // </div>
                            } 
                            <div className="widget footer-widget col-xs-4 col-sm-4 col-md-4 wow fadeIn widget4 widget_social">
                                <h4 className="widget_title_two">Products</h4>
                                <ul className="social-icon">
                                {
                                    // <li><a href="./en/RegisterOptions">Calorie Calculator by Freshbit</a></li>
                                }
                                    <li><a href="https://play.google.com/store/apps/details?id=com.bitapps.freshbit">Freshbit on Google Play</a></li>
                                    <li><a href="https://itunes.apple.com/app/id1403059908">Freshbit on App Store</a></li>
                                {
                                    // <li><a href="http://www.nutritioninfo.net">Nutritioninfo.net</a></li>
                                }
                                </ul>
                            </div>
                            <div className="widget footer-widget col-xs-4 col-sm-4 col-md-4 wow fadeIn widget4 widget_social">
                                <h4 className="widget_title_two">Other Links</h4>
                                <ul className="social-icon">
                                    <li><a href="https://www.shareasale.com/shareasale.cfm?merchantID=110102">Affiliate Program</a></li>
                                    <li><a href="https://caloriecalculator.ai/ppolicy.html">Privacy Policy</a></li>
                                    <li><a href="https://caloriecalculator.ai/terms.html">Terms of Service</a></li>
                                </ul>
                            </div>  
                            <div className="widget footer-widget col-xs-4 col-sm-4 col-md-4 wow fadeIn widget4 widget_social">
                                <h4 className="widget_title_two">Contact Us</h4>
                                <ul className="social-icon">
                                    <li><a href="mailto:afoodapp@gmail.com?subject=Feedback">Email Us</a></li>
                                </ul>
                            </div>  
                        </div>
                    </div>
                </div>
                <div className="row m0 footer_bottom text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <p>2021 <a href=".">Honeycrisp Technologies, Inc.</a>. All rights reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;